input[type=number] {
  input[type=number] {
    appearance: none; /* Estandarizado */
    -webkit-appearance: none; /* Para compatibilidad con WebKit */
    -moz-appearance: none; /* Mantenerlo si necesitas compatibilidad con versiones antiguas de Firefox */
  }
  }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
